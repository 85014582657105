import axios from 'axios'


const service = axios.create({
  // baseURL: 'https://44284712a7.goho.co/renren-admin', //process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_API_URL : 'http://192.168.209.128/api/renren-admin',
  // baseURL: '/api', // url = base url + request url
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 100000, // request timeout
})

service.interceptors.request.use(
  config => {

    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)
export default service;