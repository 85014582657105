<template>
  <div class="header">
    <div class="imgdiv">
      <img src="../assets/logo (1).png" alt class="headerimg" />
    </div>
    <div class="flexcenter">
      <div class="flexcolumn">
        <div>
          <img src="@/assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;13277917989
        </div>
        <!-- <div>
              <img src="./assets/路径 (9).png" alt class="loading" /> +86 18627106967
        </div>-->
      </div>
      <div>
        <img src="@/assets/矩形 24 (1).png" alt class="reactimg" />
      </div>
      <div class="flexcolumn">
        <div>
          <img src="@/assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;18627106967
        </div>
        <!-- <div>
              <img src="./assets/路径 (9).png" alt class="loading" /> +86 18627106967
        </div>-->
      </div>
      <!-- <div>
        <img src="@/assets/矩形 24 (1).png" alt class="reactimg" />
      </div>-->
      <!-- <div class="flexcolumn">
        <div>
          <img src="@/assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;17771477335
      </div>-->
      <!-- <div>
              <img src="./assets/路径 (10).png" alt class="loading2" /> crtvnm@163.com
      </div>-->
      <!-- </div> -->
      <!-- <div>
        <img src="@/assets//矩形 24 (1).png" alt class="reactimg" />
      </div>-->
      <!-- <div class="flexcolumn">
        <div>
          <img src="@/assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;19232727515
        </div>
        <!-- <div>
              <img src="./assets/路径 (9).png" alt class="loading" /> +86 18627106967
      </div>-->
      <!-- </div> -->
    </div>
    <div class="menulist">
      <div
        v-for="(item,index) in menulist"
        :key="index"
        class="menuitem"
        :class="{activeitem:active===index}"
        @click="change(index)"
      >
        <!-- <span>{{item}}</span> -->
        <a :href="`#section${index+1}`">{{item}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['num'],
  data() {
    return {
      active: 0,
      menulist: [
        '首页',
        '采集设备',
        '真人手办',
        '合作方式',
        '关于趣玩',
        '联系我们',
      ],
    }
  },
  watch: {
    num: {
      handler(newval) {
        if (window.innerWidth == 1920) {
          if (newval < window.innerHeight / 2) {
            this.active = 0
          } else if (
            newval == window.innerHeight / 2 ||
            (newval > window.innerHeight / 2 &&
              newval < window.innerHeight * 1.5)
          ) {
            this.active = 1
          } else if (
            newval == window.innerHeight * 1.5 ||
            (newval > window.innerHeight * 1.5 &&
              newval < window.innerHeight * 2.5)
          ) {
            this.active = 2
          } else if (
            newval == window.innerHeight * 2.5 ||
            (newval > window.innerHeight * 2.5 &&
              newval < window.innerHeight * 3.5)
          ) {
            this.active = 3
          } else if (
            newval == window.innerHeight * 3.5 ||
            (newval > window.innerHeight * 3.5 &&
              newval < window.innerHeight * 4.5)
          ) {
            this.active = 4
          } else if (
            newval == window.innerHeight * 4.5 ||
            newval > window.innerHeight * 4.5
          ) {
            this.active = 5
          }
          this.$emit('event-name', this.active)
        } else {
          if (newval < (1080 * window.innerWidth) / 1920 / 2) {
            this.active = 0
          } else if (
            newval == (1080 * window.innerWidth) / 1920 / 2 ||
            (newval > (1080 * window.innerWidth) / 1920 / 2 &&
              newval < ((1080 * window.innerWidth) / 1920) * 1.5)
          ) {
            this.active = 1
          } else if (
            newval == ((1080 * window.innerWidth) / 1920) * 1.5 ||
            (newval > ((1080 * window.innerWidth) / 1920) * 1.5 &&
              newval < ((1080 * window.innerWidth) / 1920) * 2.5)
          ) {
            this.active = 2
          } else if (
            newval == ((1080 * window.innerWidth) / 1920) * 2.5 ||
            (newval > ((1080 * window.innerWidth) / 1920) * 2.5 &&
              newval < ((1080 * window.innerWidth) / 1920) * 3.5)
          ) {
            this.active = 3
          } else if (
            newval == ((1080 * window.innerWidth) / 1920) * 3.5 ||
            (newval > ((1080 * window.innerWidth) / 1920) * 3.5 &&
              newval < ((1080 * window.innerWidth) / 1920) * 4.5)
          ) {
            this.active = 4
          } else if (
            newval == ((1080 * window.innerWidth) / 1920) * 4.5 ||
            newval > ((1080 * window.innerWidth) / 1920) * 4.5
          ) {
            this.active = 5
          }
          this.$emit('event-name', this.active)
        }

        // if (window.outerHeight === screen.availHeight) {
        //   console.log('不是全屏')
        //   if (newval < window.innerHeight / 2) {
        //     this.active = 0
        //   } else if (
        //     newval == window.innerHeight / 2 ||
        //     (newval > window.innerHeight / 2 &&
        //       newval < window.innerHeight * 1.5)
        //   ) {
        //     this.active = 1
        //   } else if (
        //     newval == window.innerHeight * 1.5 ||
        //     (newval > window.innerHeight * 1.5 &&
        //       newval < window.innerHeight * 2.5)
        //   ) {
        //     this.active = 2
        //   } else if (
        //     newval == window.innerHeight * 2.5 ||
        //     (newval > window.innerHeight * 2.5 &&
        //       newval < window.innerHeight * 3.5)
        //   ) {
        //     this.active = 3
        //   } else if (
        //     newval == window.innerHeight * 3.5 ||
        //     newval > window.innerHeight * 3.5
        //   ) {
        //     this.active = 4
        //   }
        //   this.$emit('event-name', this.active)
        // } else {
        //   console.log('全屏')
        //   if (newval < 540) {
        //     this.active = 0
        //   } else if (newval == 540 || (newval > 540 && newval < 1620)) {
        //     this.active = 1
        //   } else if (newval == 1620 || (newval > 1620 && newval < 2700)) {
        //     this.active = 2
        //   } else if (newval == 2700 || (newval > 2700 && newval < 3780)) {
        //     this.active = 3
        //   } else if (newval == 3780 || newval > 3780) {
        //     this.active = 4
        //   }
        //   this.$emit('event-name', this.active)
        // }
      },
    },
  },
  methods: {
    change(index) {
      this.active = index
      this.$emit('event-name', this.active)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  // top: 0px;
  // left: 0px;
  width: 100vw;
  height: 70px;
  background: #f8e075;
  color: black;
  font-size: 20px;
  padding: 0px 169px 0px 169px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // overflow: hidden;

  .headerimg {
    width: 212px;
    height: 55px;
  }
  .menulist {
    //  width: 640px;
    height: 70px;
    display: flex;
    align-items: center;
    .menuitem {
      margin: 0px 10px;
      padding: 13px 16px;
      cursor: pointer;
    }
    .activeitem {
      background: #fef7ec;
      border-radius: 10px;
      box-sizing: border-box;
    }
  }
  a {
    text-decoration: none; /* 添加默认的下划线 */
    color: inherit; /* 继承父元素的颜色 */
  }
  .flexcenter {
    //  border: 1px solid red;
    width: 960px;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    transform-origin: left;
    font-size: 24px;
    .reactimg {
      width: 3px;
      height: 50px;
      margin: 0px 50px;
    }
    .loading {
      width: 20px;
      height: 20px;
    }
    .loading2 {
      width: 25px;
      height: 20px;
    }
    .flexcolumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>