<template>
  <!-- <div class="good" :class="{'good1':isfull===true}" ref="good"> -->

  <div class="app" ref="app">
    <div ref="app0" class="app0" id="section1">
      <div ref="item1" :class="{'scale':isfull===true}">
        <Header :num="num" @event-name="getactive" />
        <el-carousel
          :interval="3000"
          arrow="hover"
          trigger="click"
          class="el-carousel1"
          height="100%"
        >
          <el-carousel-item
            v-for="(item,index) in firstimglist"
            :key="index"
            class="el-carousel-item1"
          >
            <div class="section" ref="item1">
              <img :src="'http://115.239.211.243:9999'+item.image" alt class="sectionimg" />
              <!-- <img src="./assets/新型纪念方式.png" alt class="img1" />
          <img src="./assets/D真人手办.png" alt class="img2" />
              <img src="./assets/2.主图 (1).png" alt class="img3" />-->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="takedevice" id="section2" ref="app1">
      <div :class="{'scale':isfull===true}" ref="item2">
        <!-- <img :src="'http://115.239.211.243:9999'+threeList[6].image" alt class="trueimg" /> -->
        <div class="background">{{twoList[0].title||''}}</div>
        <el-carousel
          :interval="3000"
          arrow="hover"
          height="39.0625vw"
          trigger="click"
          class="el-carousel2"
        >
          <el-carousel-item v-for="(item,index) in twoList" :key="index" class="el-carousel-item2">
            <div class="light">
              <div>
                <img :src="'http://115.239.211.243:9999'+item.image" alt class="lightimg" />
              </div>
              <div class="right">
                <div class="title">{{item.mainHeading}}</div>
                <div class="english">{{item.subHeading}}</div>
                <div class="througth"></div>
                <div class="word">{{item.textContent}}</div>
                <div class="button">{{item.buttonContent}}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="truepeople" id="section3" ref="app2">
      <div :class="{'scale':isfull===true}" ref="item3">
        <div class="long1">9cm</div>
        <div class="long2">12cm</div>
        <div class="long3">18cm</div>
        <div class="trueban">{{this.threeList[0].title}}</div>
        <img
          :class="`img1${index+1}`"
          v-for="(item,index) in this.threeList"
          :key="index"
          :src="`http://115.239.211.243:9999`+item.image"
          alt
        />
      </div>
    </div>
    <div class="hezuo" id="section4" ref="app3">
      <div :class="{'scale':isfull===true}" ref="item4">
        <!-- <img :src="'http://115.239.211.243:9999'+threeList[6].image" alt class="trueimg" /> -->
        <div class="hezuotitle">{{fourList[0].title}}</div>
        <img
          :class="[`img11${index+1}`]"
          v-for="(item1,index) in fourList[0].image.split(',')"
          :key="index"
          :src="'http://115.239.211.243:9999'+item1"
          alt
        />
        <img src="./assets/连接线 1 (1).png" alt class="img117" />
        <img src="./assets/连接线 2 (1).png" alt class="img118" />
        <img src="./assets/连接线 3 (1).png" alt class="img119" />
        <img src="./assets/连接线 4 (1).png" alt class="img120" />
        <img src="./assets/连接线 5 (1).png" alt class="img121" />
        <div
          :class="[`jw${index+1}`]"
          v-for="(item1,index) in fourList[0].textContent.split(';')"
          :key="index"
        >{{item1}}</div>
        <div class="bottomword">{{fourList[0].bottomHeading}}</div>
      </div>
    </div>
    <div class="contectplay" id="section5" ref="app4">
      <div :class="{'scale':isfull===true}" ref="item6">
        <ContectplayVue />
      </div>
    </div>
    <div class="footer" id="section6" ref="app5">
      <div :class="{'scale':isfull===true}" ref="item5">
        <div class="play">{{this.footList[0].title}}</div>
        <div class="FLv">
          <div class="newdiv">
            <!-- <img src="./assets/12.公司.png" alt class="newimg" /> -->
            <img :src="'http://115.239.211.243:9999'+footList[0].image" alt class="newimg" />
          </div>
          <div class="flexstart">
            <div class="itemflex">
              <div class="titlebackground">{{this.footList[0].subHeading}}</div>
              <div class="longword">
                <p
                  class="p"
                  v-for="(item,index) in this.footList[0].textContent.split(';')"
                  :key="index"
                >{{item}}</p>
              </div>
            </div>
            <div class="itemflex1">
              <div class="titlebackground">{{this.footList[1].subHeading}}</div>
              <div class="longword">
                <p
                  class="p"
                  v-for="(item,index) in this.footList[1].textContent.split(';')"
                  :key="index"
                >{{item}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="maxbottom">
        <img src="./assets/pixcv_image (1).png" alt class="bottomimg" />
        <!-- <div class="bottomword">
          趣玩品牌下的3D摄影设备是给真人拍照。
          <br />生成3D立体照片/3D数字人的专业高精度设备。
          <br />3D数字人可用于做真人手办、数字人直播、VR游戏、3D试衣服等。
          <br />真人手办可以用于小孩成长、新人结婚等留作纪念，深受年轻人喜欢。
        </div>-->
        <div class="flexcenter">
          <div class="flexcolumn">
            <div>
              <img src="./assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;13277917989
            </div>
            <!-- <div>
              <img src="./assets/路径 (9).png" alt class="loading" /> +86 18627106967
            </div>-->
          </div>
          <div>
            <img src="./assets/矩形 24 (1).png" alt class="reactimg" />
          </div>
          <div class="flexcolumn">
            <div>
              <img src="./assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;18627106967
            </div>
            <!-- <div>
              <img src="./assets/路径 (9).png" alt class="loading" /> +86 18627106967
            </div>-->
          </div>
          <!-- <div>
            <img src="./assets/矩形 24 (1).png" alt class="reactimg" />
          </div>-->
          <!-- <div class="flexcolumn">
            <div>
              <img src="./assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;17771477335
            </div>
            <!-- <div>
              <img src="./assets/路径 (10).png" alt class="loading2" /> crtvnm@163.com
          </div>-->
          <!-- </div> -->
          <!-- <div>
            <img src="./assets//矩形 24 (1).png" alt class="reactimg" />
          </div>-->
          <!-- <div class="flexcolumn">
            <div>
              <img src="./assets/路径 (9).png" alt class="loading" />&nbsp;&nbsp;19232727515
            </div>
            <!-- <div>
              <img src="./assets/路径 (9).png" alt class="loading" /> +86 18627106967
          </div>-->
          <!-- </div> -->
        </div>
        <div class="gzh">
          <img src="./assets/2.png" alt class="weixinpublic" />
          <br />
          <div class="yellow">微信公众号</div>
        </div>
        <div class="zswx">
          <img src="./assets/3.png" alt class="weixinpublic" />
          <br />
          <div class="yellow">招商微信</div>
        </div>
        <div class="bottomposition">
          <img src="./assets/路径 (11).png" alt class="loading3" />湖北省武汉市江夏区微格光谷科技园A区01
        </div>
        <div class="bottomcopy">
          <div>
            <img src="./assets/直线 1（边框）.png" alt class="bordertop" />
          </div>武汉智趣玩科技有限公司 版权所有
          <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023023269号-2</a>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Header from './views/header.vue'
import {
  firstpages,
  takedeviced,
  truepeople,
  andsomething,
  contectionplay,
} from './api/index'
import ContectplayVue from './views/contectplay.vue'
export default {
  data() {
    return {
      // height: '44.27083333333333vw', //'52.60417vw',
      isfull: false,
      xscale: '',
      yscale: '',
      num: 0,
      firstimglist: [],
      twoList: [],
      threeList: [],
      fourList: [],
      footList: [],
      distencenum: null,
    }
  },
  components: {
    Header,
    ContectplayVue,
  },
  created() {
    this.init()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.checkFullScreen) // 监听全屏变化事件
    setTimeout(() => {
      this.checkFullScreen()
    }, 1000)
  },
  methods: {
    getactive(a) {
      this.distencenum = a
      console.log(this.distencenum)
    },
    checkFullScreen() {
      console.log('监听', window.innerHeight)
      // this.height = `${(window.innerHeight - 70) / 19.2}vw`
      if (window.innerWidth == 1920) {
        this.$refs.app0.style.height = `${window.innerHeight}px`
        this.$refs.app1.style.height = `${window.innerHeight}px`
        this.$refs.app2.style.height = `${window.innerHeight}px`
        this.$refs.app3.style.height = `${window.innerHeight}px`
        this.$refs.app4.style.height = `${window.innerHeight}px`
        this.$refs.app5.style.height = `${window.innerHeight}px`

        this.$refs.item1.style.transform = `scale(${window.innerWidth / 1920},${
          window.innerHeight / 1080
        })`
        if (this.distencenum == 0) {
          window.scrollTo(0, 0)
        } else if (this.distencenum == 1) {
          window.scrollTo(0, `${window.innerHeight}`)
        } else if (this.distencenum == 2) {
          window.scrollTo(0, `${window.innerHeight * 2}`)
        } else if (this.distencenum == 3) {
          window.scrollTo(0, `${window.innerHeight * 3}`)
        } else if (this.distencenum == 4) {
          setTimeout(() => {
            window.scrollTo(0, `${window.innerHeight * 4}`)
          }, 100)
        } else if (this.distencenum == 5) {
          setTimeout(() => {
            window.scrollTo(0, `${window.innerHeight * 5}`)
          }, 100)
        }
      } else {
        if (this.distencenum == 0) {
          window.scrollTo(0, 0)
        } else if (this.distencenum == 1) {
          window.scrollTo(0, `${(1080 * window.innerWidth) / 1920}`)
        } else if (this.distencenum == 2) {
          window.scrollTo(0, `${((1080 * window.innerWidth) / 1920) * 2}`)
        } else if (this.distencenum == 3) {
          window.scrollTo(0, `${((1080 * window.innerWidth) / 1920) * 3}`)
        } else if (this.distencenum == 4) {
          setTimeout(() => {
            window.scrollTo(0, `${((1080 * window.innerWidth) / 1920) * 4}`)
          }, 100)
        } else if (this.distencenum == 5) {
          setTimeout(() => {
            window.scrollTo(0, `${((1080 * window.innerWidth) / 1920) * 5}`)
          }, 100)
        }
      }
      if (window.outerHeight === screen.availHeight) {
        this.isfull = true
        console.log('不是全屏')
      } else {
        this.isfull = false
        console.log('全屏')
      }
    },
    init() {
      firstpages().then((rst) => {
        // console.log(rst.data.records)
        this.firstimglist = rst.data.records
      })
      takedeviced().then((rst) => {
        // console.log(rst.data.records)
        this.twoList = rst.data.records
      })
      truepeople().then((rst) => {
        //console.log(rst.data.records)
        this.threeList = rst.data.records
      })
      andsomething().then((rst) => {
        // console.log(rst.data.records)
        this.fourList = rst.data.records
      })
      contectionplay().then((rst) => {
        //console.log(rst.data.records)
        this.footList = rst.data.records
      })
    },
    handleScroll() {
      let top = Math.floor(
        document.documentElement.scrollTop || document.body.scrollTop
      )
      console.log(top)
      this.num = top
    },
  },
}
</script>

<style lang="scss" scoped>
.good {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 5400px;
  background: black;
}
.app {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  // height: 5400px;
  overflow: hidden;
  // transform: scale(0.8518518518518519, 0.8518518518518519);

  .app0 {
    position: relative;
    height: 1080px;
    // background: #fef7ec;

    .el-carousel1 {
      position: relative;
      // top: 70px;
      // left: 0px;
      width: 100%;
      height: 1010px;
      //overflow: hidden;

      ::v-deep .el-carousel__button {
        // 指示器按钮
        width: 16px;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      ::v-deep .is-active .el-carousel__button {
        // 指示器激活按钮
        background: #5e65ef;
      }
    }
    .el-carousel-item1 {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 1010px;
    }
    .section {
      //transform: scale(0.8518518518518519, 0.8518518518518519);
      // transform-origin: 50% 0;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 1010px;
      background: #fef7ec;
      .sectionimg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 1920px;
        height: 1010px;
      }
      // .img1 {
      //   width: 838px;
      //   height: 133px;
      //   position: absolute;
      //   left: 169px;
      //   top: 346px;
      // }
      // .img2 {
      //   width: 670px;
      //   height: 135px;
      //   position: absolute;
      //   left: 169px;
      //   top: 566px;
      // }
      // .img3 {
      //   width: 650px;
      //   height: 833.4px;
      //   position: absolute;
      //   left: 1136px;
      //   top: 247px;
      // }
    }
  }

  .takedevice {
    position: relative;
    // top: 1080px;
    // left: 0px;
    width: 100%;
    height: 1080px;
    background: #f8e075;

    .el-carousel2 {
      position: absolute;
      left: 161px;
      top: 227px;
      // top: 21%;
      width: 1600px;
      height: 750px;

      ::v-deep .el-carousel__button {
        // 指示器按钮
        width: 16px;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.3);
      }

      ::v-deep .is-active .el-carousel__button {
        // 指示器激活按钮
        background: #5e65ef;
      }
    }
    .el-carousel-item2 {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 1600px;
      height: 750px;
    }
    .background {
      position: absolute;
      top: 44px;
      left: 572px;
      text-align: center;
      width: 775.06px;
      height: 105px;
      background: url('./assets/矩形 12.png') no-repeat;
      background-size: cover;
      font-size: 50px;
      color: #3d3d3d;
      font-weight: 700;
    }
    .light {
      display: flex;
      padding-left: 161px;
      box-sizing: border-box;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 1600px;
      height: 750px;
      opacity: 1;
      background: #fef7ec;
      // background: rgba(254, 247, 236, 0.28);
      // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4),
      //   0px 7px 13px -3px rgba(0, 0, 0, 0.3),
      //   inset 0px -3px 0px 0px rgba(0, 0, 0, 0.2);
      .lightimg {
        width: 500px;
        height: 699.12px;
      }
      .right {
        padding-top: 136px;
        .title {
          font-size: 50px;
          color: #3d3d3d;
          font-weight: 500;
          letter-spacing: 0.26em;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
        }
        .english {
          font-weight: 900;
          line-height: 50px;
          font-family: Abril Fatface;
          font-size: 30px;
          font-weight: normal;
          color: #3d3d3d;
        }
        .word {
          font-family: Source Han Sans;
          font-size: 24px;
          font-weight: normal;
          line-height: 60px;
          color: #3d3d3d;
          margin-top: 45px;
        }
        .througth {
          width: 140px;
          height: 10px;
          background: url('./assets/矩形 14.png') no-repeat;
          background-size: 140px 10px;
        }
        .button {
          text-align: center;
          font-size: 24px;
          width: 140px;
          height: 40px;
          line-height: 40px;
          background: url('./assets/矩形 15.png') no-repeat;
          background-size: 140px 40px;
          color: white;
          font-weight: 700;
          margin-top: 51px;
        }
      }
    }
  }
  .truepeople {
    position: relative;
    // left: 0px;
    // top: 2160px;
    width: 100%;
    height: 1080px;
    background: #fef7ec;
    // border: 1px solid red;
    .long1 {
      position: absolute;
      top: 939px;
      left: 239px;
    }
    .long2 {
      position: absolute;
      top: 939px;
      left: 414px;
    }
    .long3 {
      position: absolute;
      top: 939px;
      left: 615px;
    }
    .trueban {
      text-align: center;
      position: absolute;
      left: 572px;
      top: 44px;
      width: 775.06px;
      height: 105px;
      background: url('./assets/矩形 12 (1).png') no-repeat;
      background-size: cover;
      font-size: 50px;
      font-weight: 700;
      color: #3d3d3d;
      //  background-color: #f8e075;
    }
    .img11 {
      position: absolute;
      left: 855px;
      top: 245px;
      width: 320px;
      height: 160px;
    }
    .img12 {
      position: absolute;
      left: 157px;
      top: 319px;
      width: 618.93px;
      height: 612.38px;
    }
    .img13 {
      position: absolute;
      left: 1167px;
      top: 420px;
      width: 360px;
      height: 160px;
    }
    .img14 {
      position: absolute;
      left: 855px;
      top: 600px;
      width: 432.63px;
      height: 160px;
    }
    .img15 {
      position: absolute;
      left: 898px;
      top: 797px;
      width: 390px;
      height: 131px;
    }
    .img16 {
      position: absolute;
      left: 1372px;
      top: 480px;
      width: 466.53px;
      height: 600px;
    }
    // .img17 {
    //   position: absolute;
    //   right: 159px;
    //   top: 0px;
    //   width: 200px;
    //   height: 197.88px;
    // }
  }
  .hezuo {
    position: relative;
    // left: 0px;
    // top: 3240px;
    width: 1920px;
    height: 1080px;
    opacity: 1;
    background: #f8e075;
    .hezuotitle {
      position: absolute;
      top: 44px;
      left: 572px;
      width: 775.06px;
      height: 105px;
      background: url('./assets/矩形 12.png') no-repeat;
      background-size: cover;
      font-size: 50px;
      font-weight: 700;
      color: #3d3d3d;
      text-align: center;
    }
    .img111 {
      position: absolute;
      width: 368.85px;
      height: 221.07px;
      left: 310px;
      top: 227px;
    }
    .img112 {
      position: absolute;
      width: 200.68px;
      height: 221.07px;
      top: 227px;
      left: 875.43px;
    }
    .img113 {
      position: absolute;
      width: 336.65px;
      height: 221.07px;
      top: 227px;
      right: 310px;
    }
    .img114 {
      position: absolute;
      width: 213.6px;
      height: 238.07px;
      top: 590.91px;
      right: 371.83px;
    }
    .img115 {
      position: absolute;
      width: 213.62px;
      height: 238.07px;
      top: 590.91px;
      right: 736.57px;
    }
    .img116 {
      position: absolute;
      width: 357.11px;
      height: 306.1px;
      top: 556.9px;
      left: 321.9px;
    }
    .img117 {
      position: absolute;
      width: 196.58px;
      height: 20px;
      left: 678.85px;
      top: 337.53px;
    }
    .img118 {
      position: absolute;
      width: 197.24px;
      height: 20px;
      top: 337.53px;
      right: 646.65px;
    }
    .img119 {
      position: absolute;
      width: 20px;
      height: 142.84px;
      top: 448.07px;
      right: 477.63px;
    }
    .img120 {
      position: absolute;
      width: 290.79px;
      height: 20px;
      top: 709.95px;
      left: 679.01px;
    }
    .img121 {
      position: absolute;
      width: 151.14px;
      height: 20px;
      top: 709.95px;
      right: 585.43px;
    }
    .jw1 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 454.5px;
      left: 532.86px;
      //  width: 368.64px;
      // height: 52px;
      //  background: url('./assets/矩形 19 (1).png') no-repeat;
      //  background-size: cover;
      background: white;
      padding: 10px;
      border-radius: 20px;
      font-size: 26px;
      color: #3d3d3d;
      font-family: STXihei;
    }
    .jw2 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 454.5px;
      right: 504.07px;
      // width: 345px;
      //   height: 55px;
      //   background: url('./assets/矩形 19 (2).png') no-repeat;
      //   background-size: cover;
      background: white;
      padding: 10px;
      border-radius: 20px;
      font-size: 26px;
      color: #3d3d3d;
      font-family: STXihei;
    }
    .jw3 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 873.29px;
      right: 435.36px;
      // width: 390px;
      //   height: 55px;
      // background: url('./assets/矩形 19 (3).png') no-repeat;
      //  background-size: cover;
      background: white;
      padding: 10px;
      border-radius: 20px;
      font-size: 26px;
      color: #3d3d3d;
      font-family: STXihei;
    }
    .jw4 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 873.29px;
      left: 338.79px;
      //   width: 358px;
      //   height: 55px;
      //   background: url('./assets/矩形 19 (4).png') no-repeat;
      //      background-size: cover;
      background: white;
      padding: 10px;
      border-radius: 20px;
      font-size: 26px;
      color: #3d3d3d;
      font-family: STXihei;
    }
    .bottomword {
      position: absolute;
      left: 157px;
      top: 961px;
      width: 1600px;
      height: 60px;
      opacity: 1;
      background: #fef7ec;
      box-shadow: 0px 54px 55px 0px rgba(0, 0, 0, 0.25),
        0px -12px 30px 0px rgba(0, 0, 0, 0.12),
        0px 4px 6px 0px rgba(0, 0, 0, 0.12),
        0px 12px 13px 0px rgba(0, 0, 0, 0.17),
        0px -3px 5px 0px rgba(0, 0, 0, 0.09);
      font-family: STXihei;
      font-size: 30px;
      font-weight: normal;
      line-height: 60px;
      text-align: center;
      letter-spacing: 0em;
    }
  }
  .footer {
    position: relative;
    // top: 4320px;
    // left: 0px;
    width: 1920px;
    height: 1080px;
    opacity: 1;
    background: #f8e075;
    .play {
      position: absolute;
      top: 44px;
      left: 572px;
      width: 775.06px;
      height: 105px;
      background: url('./assets/矩形 12.png') no-repeat;
      background-size: cover;
      font-size: 50px;
      font-weight: 700;
      color: #3d3d3d;
      text-align: center;
    }
    .five {
      position: absolute;
      width: 1000px;
      height: 257px;
      top: 227px;
      left: 460px;
    }
    .FLv {
      //border: 1px solid red;
      width: 100%;
      // height: 875px;
      position: absolute;
      top: 180px;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .newdiv {
        width: 768px;
        height: 480px;
        //   border: 1px solid red;
        .newimg {
          width: 768px;
          height: 480px;
        }
      }
      .flexstart {
        //   border: 1px solid red;
        //  position: absolute;
        // width: 100%;
        width: 723px;
        height: 572px;
        //top: 50px;
        //  right: 180px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        .itemflex {
          font-size: 20px;
          // font-weight: 600;
          color: #3d3d3d;
          //  width: 624px;
          //  height: 281px;
          margin: 0px 60px;
          //  border: 1px solid red;
        }
        .itemflex1 {
          font-size: 20px;
          // font-weight: 600;
          color: #3d3d3d;
          //  width: 713px;
          //   height: 281px;
          margin: 0px 60px;
          //  border: 1px solid red;
        }
        .titlebackground {
          width: 160px;
          height: 50px;
          background: url('./assets/矩形 21.png') no-repeat 50% 38%;
          background-size: 280px 170px;
          overflow: hidden;
          border-radius: 12px;
          font-size: 26px;
          text-align: center;
          line-height: 50px;
          font-weight: 900;
          margin-bottom: 31px;
          box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
            0px 5px 10px 0px rgba(0, 0, 0, 0.2),
            0px 15px 40px 0px rgba(0, 0, 0, 0.4);
        }
        .longword {
          line-height: 50px;
          color: #3d3d3d;
          .p {
            margin: 0px;
            padding: 0px;
          }
        }
      }
    }
    .maxbottom {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 205px;
      //  border: 1px solid red;
      opacity: 1;
      background: #fef7ec;
      .bottomimg {
        position: absolute;
        width: 100px;
        height: 83.23px;
        left: 220px;
        top: 46px;
      }
      // .bottomword {
      //   font-size: 16px;
      //   position: absolute;
      //   line-height: 30px;
      //   top: 186px;
      //   left: 309px;
      //   color: #3d3d3d;
      // }
      .flexcenter {
        //  border: 1px solid red;
        width: 960px;
        display: flex;
        justify-content: flex-start;
        position: absolute;
        top: 40px;
        left: 343px;
        font-size: 24px;
        .reactimg {
          width: 3px;
          height: 50px;
          margin: 0px 50px;
        }
        .loading {
          width: 20px;
          height: 20px;
        }
        .loading2 {
          width: 25px;
          height: 20px;
        }
        .flexcolumn {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .weixinpublic {
        width: 120px;
        height: 117.9px;
      }
      .gzh {
        position: absolute;
        top: 24px;
        right: 319px;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
      }
      .zswx {
        position: absolute;
        top: 21px;
        right: 178px;
        text-align: center;
        color: #ffffff;
        font-size: 18px;
      }
      .yellow {
        color: #fbb712;
        font-size: 14px;
      }
      .bottomposition {
        position: absolute;
        top: 120px;
        left: 566px;
        font-size: 20px;
        color: #3d3d3d;
        img {
          width: 20px;
          height: 23px;
          vertical-align: middle;
          margin-right: 10.43px;
        }
      }
      .bottomcopy {
        width: 100%;
        position: absolute;
        bottom: 15px;
        text-align: center;
        font-size: 18px;
        color: #3d3d3d;
        height: 40px;

        .bordertop {
          width: 1920px;
          height: 1px;
        }
      }
    }
  }
  .trueimg {
    position: absolute;
    right: 159px;
    top: 0px;
    width: 200px;
    height: 197.88px;
  }
}

.contectplay {
  position: relative;
  width: 1920px;
  height: 1080px;
  background: #fef7ec;
}
// .activeapp {
//   transform: scale(1, 1);
//   //transform-origin: 50% 0;
// }

@media (width: 1920px) {
  .scale {
    //  border: 1px solid solid;
    // position: absolute;
    // top: 50%;
    // transform: translate();
    transform: scale(0.8518518518518519, 0.8518518518518519);
    transform-origin: 50% 0%;
    // width: 100%;
    height: 100%;
    //border: 1px solid red;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
}
</style>