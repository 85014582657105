<template>
  <div>
    <div class="play">{{datList[0].title}}</div>
    <div class="flex">
      <div class="flex1">
        <div class="flexword margin">
          <!-- <div class="wordself">趣玩品牌下的3D摄影设备是给真人拍照，</div>
          <div class="wordself">生成3D立体照片/3D数字人的专业高精度设备。</div>
          <div class="wordself">D数字人可用于做真人手办、数字人直播、VR游戏、3D试衣服等。</div>
          <div class="wordself">真人手办可以用于小孩成长、新人结婚等留作纪念，深受年轻人喜欢。</div>-->
          <div
            class="wordself"
            v-for="(item,index) in datList[0].textContent.split(';')"
            :key="index"
          >{{item}}</div>
        </div>
        <div class="imgdiv margin">
          <img :src="'http://115.239.211.243:9999'+datList[0].image" alt class="flex1img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aboutplay } from '../api/index'
export default {
  data() {
    return {
      datList: '',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let that = this
      aboutplay().then((rst) => {
        // console.log(rst)
        that.datList = rst.data.records
        // console.log(that.datList)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.play {
  position: absolute;
  left: 576px;
  top: 62px;
  width: 775.06px;
  height: 105px;
  opacity: 1;
  background: url('../assets/矩形 12 (1).png') no-repeat;
  background-size: cover;
  font-size: 50px;
  font-weight: 700;
  color: #3d3d3d;
  text-align: center;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  .flex1 {
    width: 100%;
    margin-top: 322px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid red;
    .imgdiv {
      width: 768px;
      height: 480px;
    }
    .flex1img {
      width: 768px;
      height: 480px;
    }
    .margin {
      margin: 0px 17px;
    }
    .flexword {
      width: 784px;
      height: 420px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      //  justify-content: center;
      // border: 1px solid red;
      .wordself {
        line-height: 70px;
        font-size: 36px;
        // font-weight: 550;
        color: #3d3d3d;
      }
    }
  }
  .flex2 {
    display: flex;
    align-items: center;
    margin-top: 150px;
    .flexture {
      width: 500px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //  border: 1px solid red;

      .dture {
        font-size: 40px;
        color: black;
        line-height: 80px;
      }
      .numbermodel {
        color: #f3ab37;
        font-size: 60px;
        line-height: 80px;
      }
    }
    .flexpicture {
      width: 900px;
      height: 300px;
      //  border: 1px solid red;
      border-top-left-radius: 150px 100px;
      border-top-right-radius: 150px 100px;
      border-bottom-left-radius: 150px 100px;
      border-bottom-right-radius: 150px 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #b6b5b7;
      //  border: 1px solid red;
      .fivescale {
        width: 650px;
        height: 167px;
        // border: 1px solid red;
      }
    }
  }
}
</style>