import service from "@/utils/request";


export function firstpages(params) {
  return service({
    url: '/index/indexBanner/list',
    method: 'get',
    params,
  })
}
export function takedeviced(params) {
  return service({
    url: '/cjsb/collectDevice/list',
    method: 'get',
    params,
  })
}
export function truepeople(params) {
  return service({
    url: '/zrsb/handmadeModel/list',
    method: 'get',
    params,
  })
}
export function andsomething(params) {
  return service({
    url: '/hzfs/cooperationWay/list',
    method: 'get',
    params,
  })
}
export function contectionplay(params) {
  return service({
    url: '/lxqw/connectionQvwan/list',
    method: 'get',
    params,
  })
}


export function aboutplay(params) {
  return service({
    url: '/gyqw/aboutQvwan/list',
    method: 'get',
    params
  })
}